import React from "react";

// Import the SVG icons
import rebrandingIcon from "../images/icon-rebranding.svg";
import designIcon from "../images/icon-design.svg";
import positioningIcon from "../images/icon-positioning.svg";

const MyApproach = () => {
  return (
    <section className="py-10 bg-black text-white max-w-[1300px] m-auto">
      <div class="separator border-t py-4"></div>
      <h2 className="text-1xl font-sans mb-10"> My approach</h2>
      <div className="flex flex-col md:flex-row flex-start md:gap-20 gap-4">
        {/* Service 1 */}
        <div className="space-y-4 text-center md:text-left">
          {/* SVG icon for Rebranding */}
          <div className="flex  justify-start">
            <img
              src={rebrandingIcon}
              alt="Rebranding Icon"
              className="w-10 h-10 mb-4"
            />
          </div>
          <h3 className="text-3xl font-sans mb-2 max-w-[279px] text-left">
          Strategic (Re)branding
          </h3>
          <p className="text-sm font-sans text-gray-300 leading-relaxed text-left">
          Your brand is your reputation. Together, we'll create strategies that help your business connect with your audience, therefore build lasting trust and grow.. From identity to messaging, every detail works to position you as a the go-to brand in your market.
          </p>
        </div>

        {/* Service 2 */}
        <div className="space-y-4 text-left">
          {/* SVG icon for Design */}
          <div className="flex justify-start">
            <img
              src={designIcon}
              alt="Design Icon"
              className="w-10 h-10 mb-4"
            />
          </div>
          <h3 className="text-3xl font-sans mb-2 max-w-[379px] text-left">
          Data-Driven Design for Business Growth
          </h3>
          <p className="text-sm font-sans text-gray-300 leading-relaxed text-left">
          Great design solves problems and drives measurable outcomes. My goal is to craft every digital experience, including websites, apps, and digital products, to engage users, build trust, and achieve your business objectives. With a focus on usability and impact, I work to deliver sustained growth.
          </p>
        </div>

        {/* Service 3 */}
        <div className="space-y-4 text-left">
          {/* SVG icon for Positioning */}
          <div className="flex justify-start">
            <img
              src={positioningIcon}
              alt="Positioning Icon"
              className="w-10 h-10 mb-4"
            />
          </div>

          <h3 className="text-3xl font-sans mb-2 max-w-[379px] text-left">
          E-Commerce Experiences That Sell
          </h3>
          <p className="text-sm font-sans text-gray-300 leading-relaxed text-left">
          E-commerce should feel effortless. I design and optimize online stores that reduce friction, engage customers, guide them to purchase, and build loyalty. From user flows to checkout, every element is built to drive sales and reflect your brand’s quality.
          </p>
        </div>
      </div>
    </section>
  );
};

export default MyApproach;
