const AboutSection = () => {
  return (
    <section
      id="about"
      className="md:py-20 py-10 bg-black text-white max-w-[1300px] m-auto"
    >
      <div class="separator border-t py-4"></div>

      <div className="flex flex-col md:flex-row justify-between md:gap-8 gap-4">
        <h2 className="text-1xl font-sans md:mb-36 mb-10"> About me</h2>
        {/* About Text */}
        <div className="max-w-[640px] ">
          <p className="mb-6 md:text-xl text-sm font-sans leading-relaxed">
            I bring 15 years of experience across business, brand, and design,
            at companies large and small. My approach is a blend of high craft
            and systems thinking, allowing me to bridge the gap between
            creativity and strategy. I began my journey in graphic design, then
            moved into branding, web, product, and leadership, adapting to the
            ever-evolving industry.
          </p>

          <p className="mb-6 md:text-xl text-sm font-sans leading-relaxed">
          Over the years, I’ve developed in-depth expertise in creating human-centered experiences that span multiple disciplines and platforms. Beyond driving results for businesses, I am deeply committed to nurturing the next generation of design leaders. As a mentor, I also guide aspiring creatives in bridging this same gap between design and strategy, while navigating the intersection of these disciplines and technology.
          </p>

          <p className="mb-6 md:text-xl text-sm font-sans leading-relaxed">
            I believe that thoughtful, well-executed design is a key driver of
            business success, and I strive to embed strategy and design into
            every aspect of execution. Design can be transformative when it is
            integrated into the very core of business strategy. The best
            companies, in my view, are those that understand design and embrace
            it as a tool for making more informed, holistic decisions. I’ve seen
            firsthand how great design and user experiences can drive lasting
            success, and it’s a belief I continue to abide by in every
            collaboration, whether with C-suite or cross-functional teams.
          </p>

          <p className="mb-6 md:text-xl text-sm font-sans leading-relaxed">
            Today, I lead Brandiaq, an independent design studio dedicated to creating well-crafted brands, intuitive products, and easy-to-use digital experiences, and Namandla, a venture design studio where we transform startups into high-growth companies through helping founders align, product design, branding, and go-to-market strategies. I also collaborate closely with leaders independently to drive meaningful impact through strategy, design and tech, and I run a non-profit with the mission to help internationals in Denmark close the gap to securing (design and tech) jobs. Integrity, empathy, and honesty define my leadership style, and I work hard to build strong, enduring relationships with both teams
            and clients, making certain that businesses (and the humans behind them)
            continuously grow in a constantly shifting landscape.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
