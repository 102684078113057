import React from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="max-w-[1300px] m-auto flex justify-between items-center md:py-10 py-4 md:px-0 px-2 bg-black text-white">
      {/* Logo */}
      <div className="md:text-2xl text-sm md:p-0 p-4">
        <NavLink to="/" className="hover:text-gray-200 transition duration-300 ease-in-out">
          al.ma
        </NavLink>
      </div>

      {/* Navigation Links */}
      <div className="md:space-x-16 space-x-2 md:text-base text-xs flex items-center">
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive
              ? "text-white"
              : "text-gray-400 hover:text-gray-200 transition duration-300 ease-in-out"
          }
        >
          home.
        </NavLink>
        <NavLink
          to="/work"
          className={({ isActive }) =>
            isActive
              ? "text-white"
              : "text-gray-400 hover:text-gray-200 transition duration-300 ease-in-out"
          }
        >
          work.
        </NavLink>
        <NavLink
          to="/contact"
          className={({ isActive }) =>
            isActive
              ? "text-white"
              : "text-gray-400 hover:text-gray-200 transition duration-300 ease-in-out"
          }
        >
          contact.
        </NavLink>

        {/* For Startups Button */}
        <a
          href="https://startups.brandiaq.com"
          target="_blank"
          rel="noopener noreferrer"
          className=" flex items-center text-indigo-400 font-semibold md:text-1xl font-sans transition duration-300 ease-in-out hover:text-indigo-500 md:pl-24 pl-4 pr-4"
        >
          For Startups
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="md:w-6 md:h-6 w-4 h-4"
          >
            <path d="M16.0037 9.41421L7.39712 18.0208L5.98291 16.6066L14.5895 8H7.00373V6H18.0037V17H16.0037V9.41421Z"></path>
          </svg>
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
