import React, { useState } from "react";

const ContactPage = () => {
  return (
    <div className="py-20">
      <div className="max-w-[1300px] m-auto">
        <h1 className="text-4xl mb-10">Get in touch</h1>
        <p className="text-lg mb-10 max-w-[600px]">
          If you would like to work with me or just get in touch, please send an email to{" "}
          <a
            href="mailto:am@brandiaq.com"
            className="text-indigo-400 hover:underline px-1"
          >
            am@brandiaq.com 
          </a> 
            explaining your project and how I can help.
        </p>
      </div>
    </div>
  );
};

export default ContactPage;
