import React, { useState } from "react";

const HeroSection = () => {
  const [activeTab, setActiveTab] = useState("brands");

  // Function to highlight specific words/phrases in a string
  const highlightText = (text, phrasesToHighlight) => {
    let highlightedText = text;
    phrasesToHighlight.forEach((phrase) => {
      const regex = new RegExp(`(${phrase})`, "gi");
      highlightedText = highlightedText.replace(
        regex,
        '<span class="font-sans text-white">$1</span>'
      );
    });
    return highlightedText;
  };

  // Define tab content and phrases to highlight
  const tabContent = {
    brands: {
      heading:
        "Hi! I'm Alexandra, a strategic brand and design leader driving global impact",
      highlight: ["right out"],
      description:
        "I create brands, websites, and products that stand out and become meaningful. With a focus on strategy, design, and execution, I specialize in turning underperforming projects into success stories. I take on one client at a time to ensure every detail gets the attention it deserves. If you're ready to design your company with growth in mind, apply to work with me.",
      descriptionHighlight: ["lead and build", "one brand at a time"],
      cta: {
        text: "Schedule a strategy call",
        href: "https://form.jotform.com/230097055290352", // JotForm URL
      },
    },
    recruiters: {
      heading:
        "I'm a global brand and design executive with over 15 years of experience.",
      highlight: ["Brand and Design Executive"],
      description:
        "My work combines strategic insight and design leadership, guiding high-growth brands, websites, and digital products from concept to execution. Having worked across a range of industries—from startups to enterprises—I make sure strategy and design work together to achieve real growth and real results.",
      descriptionHighlight: ["business objectives", "design excellence"],
      cta: {
        text: "Download CV",
        href: "/AM_Global-compressed.pdf",
      },
    },
    designers: {
      heading:
        "I bring craft and strategy together, leading and designing human-centered experiences.",
      highlight: ["craft and strategy"],
      description:
        "With an end-to-end approach that spans vision, strategy, and execution, my foundation in graphic design, branding, web design, and leadership enables me to lead teams while staying hands-on, creating impactful, user-driven solutions across multiple disciplines.",
      descriptionHighlight: ["vision and strategy"],
      cta: {
        text: "Let’s collaborate",
        href: "/contact",
      },
    },

    engineers: {
      heading:
        "I code — {a little} and deliver practical handoffs with buildable design solutions.",
      highlight: ["buildable design solutions."],
      description:
        'I built (this.site) from scratch using React and Tailwind CSS, and <a href="https://brandiaq.com" target="_blank" class="border-b hover:text-purple-400 hover:border-b-purple-400">(this.one)</a> with Nunjucks, Sass, and vanilla JS. My focus is always on making sure that design is both visually impactful, functional, user-oriented and practically feasible for development.',
      descriptionHighlight: [],
      cta: {
        text: "Work with me",
        href: "/contact",
      },
    },
  };

  return (
    <section className="bg-black md:mt-20 py-5 max-w-[1300px] m-auto">
      <div className="text-white text-left">
        {/* Tab Navigation */}
        <div className="flex gap-8 mb-8">
          <button
            className={`md:text-sm text-xs transition duration-300 ease-in-out text-left ${
              activeTab === "brands"
                ? " text-white"
                : "text-gray-500 hover:text-white"
            }`}
            onClick={() => setActiveTab("brands")}
          >
            For brands
          </button>
          <button
            className={`md:text-sm text-xs transition duration-300 ease-in-out text-left ${
              activeTab === "recruiters"
                ? " text-white"
                : "text-gray-500 hover:text-white"
            }`}
            onClick={() => setActiveTab("recruiters")}
          >
            For recruiters
          </button>
          <button
            className={`md:text-sm text-xs transition duration-300 ease-in-out text-left ${
              activeTab === "designers"
                ? " text-white"
                : "text-gray-500 hover:text-white"
            }`}
            onClick={() => setActiveTab("designers")}
          >
            For design teams
          </button>
          <button
            className={`md:text-sm text-xs transition duration-300 ease-in-out text-left ${
              activeTab === "engineers"
                ? " text-white"
                : "text-gray-500 hover:text-white"
            }`}
            onClick={() => setActiveTab("engineers")}
          >
            For engineers
          </button>
        </div>

        {/* Tab Content */}
        <div className="tab-content text-left">
          {/* Render heading with highlighted words/phrases */}
          <h1
            className="md:text-7xl text-4xl mb-6 font-sans max-w-[1100px] text-left"
            dangerouslySetInnerHTML={{
              __html: highlightText(
                tabContent[activeTab].heading,
                tabContent[activeTab].highlight
              ),
            }}
          ></h1>

          {/* Render description with highlighted phrases */}
          <p
            className="text-m font-sans text-gray-300 text-left mb-6 max-w-[750px]"
            dangerouslySetInnerHTML={{
              __html: highlightText(
                tabContent[activeTab].description,
                tabContent[activeTab].descriptionHighlight
              ),
            }}
          ></p>

          {tabContent[activeTab].cta && (
            <a
              href={tabContent[activeTab].cta.href}
              className="group flex items-center justify-start text-indigo-400 font-semibold text-1xl border-current pb-2 font-sans transition duration-300 ease-in-out hover:text-indigo-500"
              target={activeTab === "brands" ? "_blank" : "_self"} // Opens the JotForm link in a new tab
              rel="noopener noreferrer"
              download={activeTab === "recruiters" ? "Alexandra_CV.pdf" : undefined} // Download attribute for recruiters tab
            >
              {tabContent[activeTab].cta.text}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path d="M16.0037 9.41421L7.39712 18.0208L5.98291 16.6066L14.5895 8H7.00373V6H18.0037V17H16.0037V9.41421Z"></path>
              </svg>
            </a>
          )}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
